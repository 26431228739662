/* const Container = styled.div`
  width: 720px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 64px;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.85);
  border-radius: 20px;

  @media (max-width: 768px) {
    max-width: 90%;
  }
` */

import styled from 'styled-components'

// Icons
import { CgCheckO } from 'react-icons/cg'

// Hooks
import useTranslate from '../../hooks/use-translate'

// Components
import LanguageSelector from '../language-container/language-container'

// Styled components

const Body = styled.div`
  height: 80vh;
  position: relative;
`
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const OkIcon = styled(CgCheckO)`
  margin-bottom: 0.5em;
  width: 3em;
  height: 3em;
  color: #68d391;
`

const Text = styled.div`
  margin-top: 0.5em;
  font-size: 1.2em;
`

export default function SuccessPage() {
  const { t } = useTranslate()

  return (
    <Body>
      <Center>
        <OkIcon />
        <Text>{t('Payment was successful!')}</Text>
      </Center>
      <LanguageSelector />
    </Body>
  )
}
