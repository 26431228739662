import React, { useState, useMemo, useEffect, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { API } from '../../api/api'
import { AppPath, Endpoint, Moon_Algorithm } from '../../consts/consts'
import { useFetchData } from '../../hooks/use-fetch-data'
import useInterval from '../../hooks/use-interval'
import PaymentDetails from '../payment-details/payment-details'
import {
  BackButton,
  Button,
  CardContainer,
  CardIcons,
  CardIconsLabel,
  Form,
  FormContainer,
  Center,
  IconsContainer,
  Input,
  MaskedInput,
  StyledInput,
  Title,
  StackV
} from './payment-form-styles'

import { MasterCard, MIR, PciDcc, VISA } from '../../icons/icons'
import Spinner from '../spinner/spinner'
import { useNavigate } from 'react-router-dom'
import useTranslate from '../../hooks/use-translate'
import LanguageSelector from '../language-container/language-container'

const Error = {
  'Empty recaptcha token' : 'Пустое значение reCAPTCHA',
  'Captcha failed': 'Неверное значение reCAPTCHA',
  'Antifraud rejection!': 'Попробуйте другую карту',
};

export default function PaymentForm({ clientInfo }) {
  // Translate function
  const { t, updateLang } = useTranslate()

  const navigate = useNavigate()
  const referrer = document.referrer

  const urlParameters = window.location.pathname.slice(1)

  const formRef = useRef()
  const threeDSLinkRef = useRef()

  const [UID] = useState({ externalID: urlParameters })
  const [cardNumber, setCardNumber] = useState(``)
  const [cardExpirationDate, setCardExpirationDate] = useState(``)
  const [CVVCode, setCVVCode] = useState(``)
  const [cardHolderName, setCardHolderName] = useState(``)
  const [paySource, setPaySource] = useState(``)
  const [isLinksLoading, setIsLinksLoading] = useState(false)
  const [threeDSLink, setThreeDSLink] = useState(``)
  const [isRedirectFromIframe] = useState(false)
  const [threeDSSuccess, setThreeDSSuccess] = useState(false)
  const [isMoonError, setIsMoonError] = useState(false)
  const [isDateError, setIsDateError] = useState(false)

  const isCardDataFullFilled = useMemo(
    () => cardNumber && CVVCode && cardExpirationDate && cardHolderName,
    [cardNumber, CVVCode, cardExpirationDate, cardHolderName]
  )
  const isCardDataInvalid = useMemo(
    () => isMoonError || isDateError,
    [isMoonError, isDateError]
  )

  const { state: paymentDetails, setTrigger } = useFetchData(
    Endpoint.INVOICE_H2H_ORDER_DATA,
    UID
  )

  const {
    state: orderStatus,
    setTrigger: checkTrigger,
    isLoading: isCheckLoading
  } = useFetchData(Endpoint.CHECK_ORDER_STATUS, UID)

  // Событие успешной транзакции
  useEffect(() => {
    if (orderStatus?.performed && orderStatus?.redirectUrl) {
      window.location.replace(`${orderStatus.redirectUrl}`)
    } else if (orderStatus?.performed) {
      navigate(`/${AppPath.ORDER_CLOSE}`, { replace: true })
    }
  }, [
    orderStatus?.performed,
    orderStatus?.redirectUrl,
    navigate,
    AppPath.SUCCESS
  ])

  // Реагируем на просроченное время платежа
  useEffect(() => {
    function checkingTime() {
      const currentUnixTime = Math.trunc(new Date().getTime() / 1000)
      if (currentUnixTime >= paymentDetails?.expireAt)
        navigate(`/${AppPath.FAIL}`, { replace: true })
    }

    const checkTimeInterval = setInterval(() => checkingTime(), 100)

    // Убираем интервал
    return () => clearInterval(checkTimeInterval)
  }, [paymentDetails?.expireAt, AppPath.FAIL, navigate])

  // Реагируем на изменение пути в iframe (redirect)

  useInterval(() => checkTrigger((state) => !state), 5000, isCheckLoading)

  // Меняем язык формы в зависимости от валюты
  useEffect(() => {
    if (paymentDetails && paymentDetails.currency === 'RUB') updateLang('ru')
    else updateLang('en')
  }, [paymentDetails, paymentDetails?.currency])

  const submitPaymentHandler = async () => {
    setIsLinksLoading(true)
    try {
      const { data } = await API({
        method: `POST`,
        url: Endpoint.GENERATE_INVOICE_H2H_CC,
        data: {
          externalID: paymentDetails.externalID,
          /* TEST 🧪 */
          /* externalID: '123', */
          clientIP: clientInfo.ip ? clientInfo.ip : '212.102.63.85',
          payerAddress: clientInfo,
          cardNumber: cardNumber.trim(),
          documentReferrer: referrer,
          cardHolderName: cardHolderName.trim(),
          cvv: CVVCode,
          expirationMonth: cardExpirationDate.slice(0, 2),
          expirationYear: cardExpirationDate.slice(3),
        }
      })
      /* TEST 🧪 */
      /* const data = {
        success: true,
        perform3DS: 'https://acs.magua-pay.com/pay/63626d5eb045b1f7a28d600a',
        redirect3DS: {
          successURL: null,
          failURL: null
        },
        typeForm: 'GET'
      } */
      setTrigger((state) => !state)
      if (data?.success) {
        const { perform3DS, success, typeForm } = data
        if (perform3DS && typeForm === 'POST' && success) {
          setThreeDSLink(perform3DS)
          setTimeout(() => {
            sendPostToUrl()
          }, 1000)
        } else if (perform3DS && typeForm === 'GET' && success) {
          setThreeDSLink(perform3DS)
          setTimeout(() => {
            window.location.replace(perform3DS)
          }, 1000)
        }
        setThreeDSSuccess(success)
      } else {
        toast.error(`${t (`Error`)}: ${data?.cause || t(`Something went wrong`)}`, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      }

      setIsLinksLoading(false)
    } catch (err) {
      setIsLinksLoading(false)
      if (err.response.status && err.response.status === 403) {
        toast.error(`${t(`Error`)}: ${(err.response.data.cause && Error[err.response.data.cause]) ? Error[err.response.data.cause] : t(`Something went wrong`)}`,  {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      }
    }
  }
  const sendPostToUrl = () => formRef.current.submit()

  const blurCardNumberHandler = ({ target }) => {
    if (Moon_Algorithm(target.value)) {
      setIsMoonError(false)
    } else {
      setIsMoonError(true)
      toast.error(
        t(`You entered the card number incorrectly. Check it out and try again`),
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        }
      )
    }
  }

  const blurCardDateHandler = ({ target }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, month, fullYear] = new Date().toLocaleDateString('ru').split('.')
    const year = fullYear.substring(2, 4)

    const [selectedMonth, selectedYear] = target.value.split('/')

    if (
      selectedYear < year ||
      (selectedYear === year && selectedMonth < month) ||
      (selectedYear >= year && selectedMonth > 12)
    ) {
      setIsDateError(true)
      toast.error(
        t(`Please enter a valid date for cards`), {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
    } else {
      setIsDateError(false)
    }
  }

  const cardHolderNameValidationHandler = ({ target }) => {
    const { value } = target

    const cardHolderRegex = /^[a-zA-Z\s]+$/

    if (value.search(cardHolderRegex) !== -1) {
      setCardHolderName(value.toUpperCase())
    } else {
      setCardHolderName((state) => state)
      toast.error(
        t(`The cardholder's name can only contain Latin characters`),
        {
          toastId: 'cardholder-error',
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        }
      )
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <FormContainer>
        {(isLinksLoading || threeDSLink) && <Spinner />}
        {isRedirectFromIframe ? (
          <Spinner />
        ) : (
          <>
            {paySource && <BackButton onClick={() => setPaySource(``)} />}
            {threeDSLink && threeDSSuccess ? (
              <form
                ref={formRef}
                encType='application/x-www-form-urlencoded'
                action={threeDSLink}
                method='POST'
              >
                <a ref={threeDSLinkRef} onClick={() => sendPostToUrl()}></a>
              </form>
            ) : null}
            <Form
              onSubmit={(evt) => {
                evt.preventDefault()
                // debugger
                submitPaymentHandler()
                // threeDSHandler()
              }}
            >
              <Center>{paymentDetails && <LanguageSelector />}</Center>
              <Title>{t('Pay for the order')}</Title>
              <StackV>
                <CardIconsLabel>{t('Enter card details')}</CardIconsLabel>
                <CardContainer>
                  <CardIcons />
                  <MaskedInput
                    placeholder={t('Card number')}
                    required
                    mask='0000 0000 0000 0000'
                    autoComplete='cc-number'
                    type='text'
                    minLength='16'
                    maxLength='19'
                    value={cardNumber}
                    style={{ marginBottom: `12px` }}
                    onAccept={(value) => setCardNumber(value)}
                    onBlur={blurCardNumberHandler}
                  />
                  <div
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      gap: `10px`,
                      marginBottom: `12px`
                    }}
                  >
                    <StyledInput
                      placeholder={t('Expire at')}
                      autoComplete='cc-exp'
                      required
                      mask='00/00'
                      type='text'
                      value={cardExpirationDate}
                      onAccept={(value) => setCardExpirationDate(value)}
                      onBlur={blurCardDateHandler}
                    />
                    <StyledInput
                      placeholder='CVV/CVC'
                      autoComplete='cc-csc'
                      required
                      mask='000'
                      type='password'
                      minLength='3'
                      maxLength='3'
                      value={CVVCode}
                      onAccept={(value) => setCVVCode(value)}
                    />
                  </div>
                  <div>
                    <Input
                      placeholder={t('Cardholder name')}
                      autoComplete='cc-name'
                      required
                      type='text'
                      value={cardHolderName}
                      onChange={cardHolderNameValidationHandler}
                    />
                  </div>
                </CardContainer>
                <Button
                  disabled={
                    isLinksLoading ||
                    isMoonError ||
                    threeDSLink ||
                    !isCardDataFullFilled ||
                    isCardDataInvalid
                  }
                  type='submit'
                >
                  {t('Pay')} {paymentDetails && paymentDetails.amount}{' '}
                  {paymentDetails && paymentDetails.currency}
                </Button>
              </StackV>
            </Form>
            {paymentDetails && paymentDetails && !isRedirectFromIframe ? (
              <PaymentDetails
                paymentDetails={paymentDetails}
                isLoading={isLinksLoading}
              />
            ) : null}
          </>
        )}
      </FormContainer>
      <IconsContainer>
        <PciDcc />
        <MasterCard />
        <VISA />
        {/* <MIR /> */}
      </IconsContainer>
      <>
        <ToastContainer />
      </>
    </div>
  )
}
