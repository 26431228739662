import React, { useEffect } from 'react'
// import Countdown from '../countdown/countdown'
import {
  Container,
  CountDownBlock,
  ExpandButton,
  InvoiceInfo,
  LinksBlock,
  ListTitle,
  Offer,
  PaymentBlock,
  PaymentLink,
  PaymentList
} from './payment-details-styles'
import Countdown from 'react-countdown'
import Spinner from '../spinner/spinner'
import { useState } from 'react'

// Hooks
import useTranslate from '../../hooks/use-translate'

export default function PaymentDetails({ paymentDetails, isLoading }) {
  const { amount, comment, expireAt, paymentFlow, redirectURL, currency } =
    paymentDetails
  const [isOpened, setIsOpened] = useState(true)
  const [isBordered, setIsBordered] = useState(false)
  // useEffect(() => {
  //   if (paymentFlow) {
  //     const isAllPayed = ({ payed }) => {
  //       return payed
  //     }
  //     const isRedirect = paymentFlow.every(isAllPayed)
  //     if (isRedirect) {
  //       setTimeout(() => {
  //         if (redirectURL) {
  //           window.location.replace(redirectURL)
  //         }
  //       }, 5000)
  //     }
  //   }
  // }, [paymentFlow])
  const resizeHandler = () => {
    if (window.innerWidth < 769) {
      setIsOpened(false)
      setIsBordered(true)
    } else {
      setIsOpened(true)
      setIsBordered(false)
    }
  }

  useEffect(() => {
    resizeHandler()
    window.addEventListener(`resize`, resizeHandler)
    return () => window.removeEventListener(`resize`, resizeHandler)
  }, [])

  const { t } = useTranslate()

  return (
    <Container
      style={{ borderBottom: isBordered && '1px solid rgb(235, 235, 235)' }}
    >
      <PaymentBlock>
        <span>{t('Amount')}</span>
        <strong>
          {amount} {currency}
        </strong>
        <ExpandButton
          isOpened={isOpened}
          onClick={() => setIsOpened((state) => !state)}
        />
      </PaymentBlock>
      {isOpened ? (
        <div>
          <InvoiceInfo>
            <label>{t('Purpose of payment transfer')}</label>
            <span>{comment}</span>
          </InvoiceInfo>
          <CountDownBlock>
            {paymentDetails &&
            paymentDetails.paymentFlow &&
            paymentDetails.paymentFlow.every(({ payed }) => payed === true) ? (
              <span
                style={{ fontWeight: `700`, color: `#000`, fontSize: `26px` }}
              >
                Оплачено
              </span>
            ) : (
              <>
                {t('Time for expiry')} {` `}
                <Countdown date={Date.now() + (expireAt * 1000 - Date.now())} />
              </>
            )}
          </CountDownBlock>
          {/* <Offer>
            Совершая перевод, вы соглашаетесь{' '}
            <a target='_blank' href='https://google.com' rel='noreferrer'>
              с офертой
            </a>
          </Offer> */}
        </div>
      ) : null}
    </Container>
  )
}
