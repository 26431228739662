import { useState } from 'react'

// Styled components
import { StyledLanguageSelect } from './language-container-styles'

// Styles of select
import { colorStyles } from '../../style/select-container'

// Hooks
import useTranslate from '../../hooks/use-translate'

const languageOptions = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Русский',
    value: 'ru'
  }
]

export default function LanguageSelector() {
  // Translate function
  const { updateLang, lang } = useTranslate()

  const [_, setLanguage] = useState(
    languageOptions.find((langItem) => langItem.value === lang)
  )

  return (
    <>
      {lang && (
        <StyledLanguageSelect
          styles={colorStyles()}
          options={languageOptions}
          value={languageOptions.find((langItem) => langItem.value === lang)}
          onChange={(lang) => {
            setLanguage(lang)
            updateLang(lang.value)
          }}
          isSearchable={false}
        />
      )}
    </>
  )
}
