import { useEffect } from 'react'

const useInterval = (action, interval, isLoading) => {
  useEffect(() => {
    const idSetInterval = setInterval(() => {
      if (!isLoading) {
        action()
      }
    }, interval)

    return () => clearInterval(idSetInterval)
  }, [isLoading])
}

export default useInterval
