import { useEffect, useState } from 'react'
import { API } from '../api/api'

export const useFetchData = (url, data = null) => {
  const [state, setState] = useState(null)
  const [isError, setIsError] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      setIsLoading(true)

      try {
        const { data: response } = await API({
          method: `POST`,
          url,
          data
        })

        if (!didCancel) {
          setState(response)
        }
      } catch (error) {
        if (!didCancel) {
          setIsError(true)
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()

    return () => {
      didCancel = true
    }
  }, [url, data, trigger])

  return { state, isError, trigger, setTrigger, isLoading }
}
