export const colorStyles = (container = null) => {
  return {
    container: (styles) => ({
      ...styles,
      ...container,
      width: `130px`,
      height: `32px`
    }),
    control: (styles) => ({
      ...styles,
      width: `100%`,
      minHeight: `inherit`,
      maxHeight: `inherit`,
      height: `inherit`,
      cursor: `pointer`,
      borderRadius: `20px`,
      fontFamily: `Inter`
    }),
    singleValue: (styles) => ({ 
      ...styles,
      display: `flex`,
      justifyContent: `flex-end`,
      fontSize: `14px`,
      fontWeight: `300`,
    }),
    valueContainer: (styles) => ({
      ...styles,
      minHeight: `inherit`,
      padding: `0`,
      marginBottom: `7px`
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      minHeight: `inherit`
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none'
    }),
    indicatorContainer: (styles) => ({
      ...styles
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: 'Inter, sans-serif'
    })
  }
}
