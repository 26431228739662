import React from 'react';
import styled, { keyframes } from 'styled-components';

const atomSpinnerAnimation1 = keyframes`
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
`

const atomSpinnerAnimation2 = keyframes`
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
`

const atomSpinnerAnimation3 = keyframes`
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
`

const Wrapper = styled.div`
  position: fixed;
  height: 50px;
  width: 50px;
  overflow: hidden;
  left: 60%;
  top: 50%;
  z-index: 1000;
`;

const WrapperInner = styled.div`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
`;

const SpinnerLine = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-duration: 1s;
  border-left-width: calc(60px / 25);
  border-top-width: calc(60px / 25);
  border-left-color: #2eab8e;
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: transparent;

  :nth-child(1) {
    animation: ${atomSpinnerAnimation1} 1s linear infinite;
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
  }

  :nth-child(2) {
    animation: ${atomSpinnerAnimation2} 1s linear infinite;
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
  }

  :nth-child(3) {
    animation: ${atomSpinnerAnimation3} 1s linear infinite;
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
  }
`;

const SpinnerCircle = styled.div`
  display: block;
  position: absolute;
  color: #2eab8e;
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Spinner = ({style = {}}) => {
  return (
    <Wrapper style={style}>
      <WrapperInner>
        <SpinnerLine />
        <SpinnerLine />
        <SpinnerLine />
        <SpinnerCircle>
          &#9679;
        </SpinnerCircle>
      </WrapperInner>
    </Wrapper>
  )
};

export default Spinner;
