import { useCallback } from 'react'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { setLang } from '../redux/slices/language'

// Translates
import ruLang from '../locales/ru.json'
import enLang from '../locales/en.json'

const useTranslate = () => {
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.language.lang)

  // Translate function
  const t = useCallback(
    (textKey) => (lang === 'ru' ? ruLang[textKey] : enLang[textKey]),
    [lang]
  )

  // Update redux value
  const updateLang = useCallback(
    (langKey) => dispatch(setLang(langKey)),
    [dispatch]
  )

  return { t, updateLang, lang }
}

export default useTranslate
