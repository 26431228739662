import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import SentryRRWeb from '@sentry/rrweb'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'

export default function useSentryLogging() {
  useEffect(() => {
    Sentry.init({
      dsn: 'https://f2d491a25b9e4fe08b758748dad0e4d5@o4504260020731904.ingest.sentry.io/4504260265902080',
      environment: process.env.REACT_SENTRY_ENV,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          )
        }),
        new SentryRRWeb()
      ],

      tracesSampleRate: 1.0
    })
  }, [])
}
