import axios from 'axios'
import { Cookies } from 'typescript-cookie'
import * as Sentry from '@sentry/react'

export const baseURL = process.env.REACT_APP_BASE_URL

export var api

export const API = axios.create({
  baseURL,
  cors: `no-cors`,
  withCredentials: false,
  headers: {
    fingerkey: Cookies.get('fingerkey')?.toString() || ''
  }
})

export const FetchRequest = axios.create({
  baseURL,
  cors: `no-cors`,
  withCredentials: false
})

const ErrorHandler = (error) => {
  // Send error to Sentry
  Sentry.captureException(error)
  // Return error from middleware
  return Promise.reject(error)
}

API.interceptors.response.use((response) => response, ErrorHandler)
FetchRequest.interceptors.response.use((response) => response, ErrorHandler)
