import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'

import { AppPath, Endpoint } from '../../consts/consts'
import { useFetchData } from '../../hooks/use-fetch-data'
import { useNavigate } from 'react-router-dom'
import PaymentForm from "../payment-form/payment-form";
import Spinner from "../spinner/spinner";
import {FormContainer} from "../payment-form/payment-form-styles";


export default function PaymentFormWrapper({clientInfo}) {
  const navigate = useNavigate()
  const urlParameters = window.location.pathname.slice(1)

  const [UID] = useState({ externalID: urlParameters })
  const [IP] = useState({
    externalID: urlParameters,
    payerIP: clientInfo.ip,
  })

  const { state: checkIsApi, isError: isApiFormError} = useFetchData(
    Endpoint.CHECK_IP_USER,
    IP
  )

  const { state: checkOpenForm, isError} = useFetchData(
    Endpoint.CHECK_OPEN_FORM,
    UID
  )

  useEffect(() => {
    if(isApiFormError || isError){
      navigate(`/${AppPath.FAIL}`, { replace: true })
    }
  }, [isApiFormError, isError])

  return (
    <div>
        {(!checkOpenForm || !checkIsApi) ? (
          <div style={{ position: 'relative' }}>
            <FormContainer>
          <Spinner style={{left: '50%', top: '45%'}} />
            </FormContainer>
          </div>
        ) : (<PaymentForm clientInfo={clientInfo} />)}
    </div>
  )
}
