export const Endpoint = {
  CHECK_OPEN_FORM: `/api/antifraud/transitions_to_form`,
  GENERATE_INVOICE_H2H_CC: `/api/payment/generate_invoice_h2h_cc`,
  INVOICE_H2H_ORDER_DATA: `/api/payment/invoice_h2h_order_data`,
  CHECK_ORDER_STATUS: `/api/payment/check_order_status`,
  CHECK_IP_USER: `/api/antifraud/validate_form_ip_access`,
}

export const AppPath = {
  ROOT: `/`,
  SUCCESS: `success`,
  FAIL: `fail`,
  ORDER_CLOSE: `order-close`
}

export const Moon_Algorithm = (setValue) => {
  let ch = 0
  const num = String(setValue).replace(/\D/g, '')
  const isOdd = num.length % 2 !== 0

  if ('' === num) return false

  for (let i = 0; i < num.length; i++) {
    let n = parseInt(num[i], 10)

    ch += (isOdd | 0) === i % 2 && 9 < (n *= 2) ? n - 9 : n
  }

  return 0 === ch % 10
}
