import React, {useEffect, useState} from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import 'regenerator-runtime/runtime';
// Redux
import { Provider as ReduxProvider } from 'react-redux'
import store from '../../redux/store'

// Styles
import GlobalStyle from '../../style/global-style'
import { themeSettings } from '../../style/theme'

// Conts
import { AppPath, Endpoint } from '../../consts/consts'

// Pages
import SuccessPage from '../success-page/success-page'
import FailPage from '../fail-page/fail-page'
import OrderClose from '../order-close-page/order-close-page'

// Components
import SentryRoutes from '../../components/sentry-routes/sentry-routes'

// Help providers
import VersionLogging from '../version-logging/version-logging'
import useSentryLogging from '../../hooks/use-sentry-logging'

import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { Cookies } from 'typescript-cookie'
import PaymentFormWrapper from "../payment-form-wrapper/payment-form-wrapper";
import { FetchRequest } from "../../api/api";

const Container = styled.div`
  width: 720px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 64px;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.85);
  border-radius: 20px;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`

export default function App() {
  // Log errors
  useSentryLogging()
  const [clientInfo, setClientInfo] = useState({
    ip: null
  })

  const getGeoInfo = async () => {
    try {
      const { data } = await FetchRequest({
        method: `GET`,
        url: 'https://ipapi.co/json/'
      })
      setClientInfo(data)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    getGeoInfo().then()
  }, [])

  useEffect(() => {
    (async () => {
      const agent = await FingerprintJS.load()
      const fingerprint = await agent.get()
      Cookies.set('fingerkey', fingerprint.visitorId, { expires: 365 })
    })()
  }, [])

  return (
    <VersionLogging>
      <ReduxProvider store={store}>
        <ThemeProvider theme={themeSettings}>
          <GlobalStyle />
          <Container>
            <BrowserRouter>
              <SentryRoutes>
                <Route path={AppPath.SUCCESS} element={<SuccessPage />} />
                <Route path={AppPath.FAIL} element={<FailPage />} />
                <Route path={AppPath.ORDER_CLOSE} element={<OrderClose />} />
                <Route path={'*'} element={clientInfo.ip && <PaymentFormWrapper clientInfo={clientInfo} />} />
              </SentryRoutes>
            </BrowserRouter>
          </Container>
        </ThemeProvider>
      </ReduxProvider>
    </VersionLogging>
  )
}
