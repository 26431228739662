/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: null
}

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload
    }
  }
})

export const { setLang } = slice.actions
export default slice.reducer
