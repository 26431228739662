import styled from 'styled-components'
import { IMaskInput as MaskInput } from 'react-imask'

export const StackV = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`

export const Form = styled.form`
  /* min-width: 50%;*/
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const MaskedInput = styled(MaskInput)`
  width: 100%;
  height: 52px;
  padding: 12px;
  background-color: rgb(247, 247, 247);
  color: rgba(0, 0, 0, 0.85);

  border: none;
  border-radius: 12px;
  ::placeholder {
    color: rgb(162, 162, 163);
  }

  :hover {
    background-color: #ebebeb;
  }

  :focus {
    background: #fff;
    box-shadow: 0 0 0 1px blue;
    outline: 0;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 52px;
  padding: 12px;
  background-color: rgb(247, 247, 247);
  color: rgba(0, 0, 0, 0.85);

  border: none;
  border-radius: 12px;
  ::placeholder {
    color: rgb(162, 162, 163);
  }

  :hover {
    background-color: #ebebeb;
  }

  :focus {
    background: #fff;
    box-shadow: 0 0 0 1px blue;
    outline: 0;
  }
`

export const StyledInput = styled(MaskedInput)`
  width: 143px;

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Button = styled.button`
  background-color: blue;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  :hover {
    opacity: 0.8;
  }

  :disabled {
    background-color: rgb(235, 235, 235);
    cursor: unset;
    color: rgb(162, 162, 163);
  }
`

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  font-family: 'Inter';
  height: 585px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
  }
`

export const OptionsContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px 18px;
  display: flex;
  border-radius: 16px;
  margin-bottom: 18px;
  box-shadow: rgb(244, 245, 246) 0px 0px 0px 1px,
    rgba(37, 33, 41, 0.04) 0px 8px 16px 0px,
    rgba(37, 33, 41, 0.04) 0px 0px 6px 0px;

  cursor: pointer;
  :hover {
    box-shadow: 0 16px 32px rgb(37 33 41 / 8%), 0 0 12px rgb(37 33 41 / 4%);
  }
`

export const Header = styled.h2`
  margin: 0;
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
`

export const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
`

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 24px;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 400;
    color: rgb(37, 33, 41);
  }

  strong {
    font-weight: 500;
  }
`

export const Radio = styled.input.attrs(() => ({
  type: 'radio'
}))`
  display: none;
`

export const BackButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  display: block;
  position: absolute;
  top: 40px;
  left: -15px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 8px 16px 0px,
    rgba(0, 0, 0, 0.04) 0px 0px 6px 0px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='left' width='1em' height='1em' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 16px 16px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;

  :hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='left' width='1em' height='1em' fill='%233fa9ff' aria-hidden='true'%3E%3Cpath d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z'%3E%3C/path%3E%3C/svg%3E");
  }
`

export const CardContainer = styled.div`
  width: 330px;
  border-radius: 16px;
  border: 1px solid rgb(235, 235, 235);
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const CardIcons = styled.div`
  width: 312px;
  height: 16px;
  margin-bottom: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='33' height='12' viewBox='0 0 33 12' fill='none' xmlns='http://www.w3.org/2000/svg' class='visa active'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0198 4.15356C17.0017 5.57996 18.2911 6.37597 19.2623 6.84924C20.2602 7.33488 20.5954 7.64627 20.5916 8.08047C20.584 8.74511 19.7955 9.03835 19.0576 9.04977C17.7702 9.06974 17.0217 8.70222 16.4266 8.4242L15.9629 10.5942C16.5599 10.8695 17.6654 11.1094 18.8119 11.1199C21.5028 11.1199 23.2634 9.79155 23.273 7.73197C23.2834 5.11818 19.6575 4.97343 19.6822 3.80507C19.6908 3.45086 20.0288 3.0728 20.7696 2.97664C21.1362 2.92812 22.1484 2.89094 23.2958 3.41939L23.7462 1.31986C23.1292 1.09512 22.3359 0.879883 21.3486 0.879883C18.8157 0.879883 17.0342 2.2263 17.0198 4.15356ZM28.074 1.06079C27.5826 1.06079 27.1684 1.34745 26.9837 1.78735L23.1396 10.9656H25.8287L26.3638 9.48681H29.6498L29.9602 10.9656H32.3303L30.262 1.06079H28.074ZM28.45 3.73649L29.2261 7.45578H27.1008L28.45 3.73649ZM13.7595 1.06079L11.6399 10.9656H14.2023L16.3209 1.06079H13.7595ZM9.96882 1.06079L7.30168 7.80237L6.22282 2.07012C6.09622 1.43029 5.59631 1.06079 5.04115 1.06079H0.68108L0.620117 1.3484C1.51522 1.54263 2.53216 1.85592 3.14821 2.1911C3.52524 2.39579 3.6329 2.57479 3.75665 3.06138L5.80005 10.9656H8.50817L12.6597 1.06079H9.96882Z' fill='%230f4595'%3E%3C/path%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg width='26' height='16' viewBox='0 0 26 16' fill='none' xmlns='http://www.w3.org/2000/svg' class='mastercard '%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.834 14.0988H10.085V1.96973H16.8342L16.834 14.0988Z' fill='%23A2A2A3'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5131 8.03415C10.5131 5.57376 11.6651 3.38205 13.4589 1.96959C12.1013 0.899093 10.4212 0.317732 8.69235 0.320321C4.43255 0.320321 0.979492 3.77386 0.979492 8.03415C0.979492 12.2944 4.43255 15.7479 8.69235 15.7479C10.4212 15.7505 12.1014 15.1691 13.4591 14.0986C11.6653 12.6864 10.5131 10.4946 10.5131 8.03415' fill='%23DCDBDD'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.9391 8.03415C25.9391 12.2944 22.4861 15.7479 18.2264 15.7479C16.4973 15.7504 14.8169 15.1691 13.459 14.0986C15.2534 12.6862 16.4054 10.4946 16.4054 8.03415C16.4054 5.5736 15.2534 3.38205 13.459 1.96958C14.8168 0.899092 16.4972 0.317813 18.2262 0.320321C22.486 0.320321 25.9391 3.77386 25.9391 8.03415' fill='%23DCDBDD'%3E%3C/path%3E%3C/svg%3E");
  background-size: auto 12px;
  background-repeat: no-repeat;
  background-position: 0 center, 47px center, 80px center;

  @media (max-width: 500px) {
    width: auto;
  }
`

export const CardIconsLabel = styled.div`
  color: #666;
  font-size: 16px;
`

export const Hint = styled.p`
  margin: 0;
  font-family: 'Inter';
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const IconsContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  bottom: -60px;
  left: 80px;

  @media (max-width: 500px) {
    right: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min-content;
  }
`

export const ThreeDSContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Iframe = styled.iframe`
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  min-height: 600px;
  background-color: #fff;
  border: none;
  border-radius: 8px;

  @media (min-width: 700px) {
    width: 600px;
  }
`

export const IframeDiv = styled.div`
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  min-height: 600px;
  background-color: #fff;
  border: none;
  border-radius: 8px;

  @media (min-width: 700px) {
    width: 600px;
  }
`
